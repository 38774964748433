<template>
  <main-layout itemMenuActive="6" tituloMenu="Reporte Consentimientos">
    <div class="container">
      <br />
      <form v-on:submit.prevent="handleSubmit">
        <div class="columns is-mobile is-multiline is-centered">
          <div class="column is-12">
            <div class="card">
              <div class="card-content">
                <b-field grouped label="Reporte de Consentimientos">
                  <b-field label="">
                    <h1 for="" style="margin-right: 1rem; line-height: 2.5rem">
                      Fecha:
                    </h1>
                    <b-datepicker
                      placeholder="Seleccione fecha..."
                      v-model="fecha"
                      range
                      :max-date="maxDate"
                    >
                    </b-datepicker>

                    <b-button
                      native-type="submit"
                      type="is-info"
                      style="margin-left: 0.5rem"
                      >Consultar</b-button
                    >
                  </b-field>
                  <div class="uk-navbar-right">
                    <b-field label="">
                      <b-button
                        :disabled="dataTable.length === 0"
                        v-on:click="exportCSV"
                        >CSV</b-button
                      >
                      <b-button
                        :disabled="dataTable.length === 0"
                        @click="isCardModalActive = true"
                        >Excel</b-button
                      >
                      <b-button
                        :disabled="dataTable.length === 0"
                        @click="isCardModalActive = true"
                        >PDF</b-button
                      >
                    </b-field>
                  </div>
                </b-field>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="columns is-mobile is-multiline is-centered">
        <div class="column is-12">
          <div class="card">
            <div class="card-content">
              <div v-if="dataTable.length > 0">
                <b-table
                  :paginated="isPaginated"
                  :per-page="perPage"
                  :pagination-rounded="isPaginationRounded"
                  :data="dataTable"
                  :narrowed="true"
                  :isBordered="true"
                  striped
                  name="tabla"
                >
                  <b-table-column
                    field="counter"
                    label="Nro."
                    numeric
                    centered
                    v-slot="props"
                  >
                    {{ props.row.counter }}
                  </b-table-column>
                  <b-table-column
                    field="fecha"
                    label="Fecha"
                    centered
                    v-slot="props"
                  >
                    <span>{{
                      dateFormating(`${props.row.consent_date}`)
                    }}</span>
                  </b-table-column>
                  <b-table-column
                    field="tipodoc"
                    label="Tipo documento"
                    centered
                    v-slot="props"
                  >
                    <span>{{ props.row.document_Type }}</span>
                  </b-table-column>
                  <b-table-column
                    field="numdoc"
                    label="Número documento"
                    centered
                    v-slot="props"
                  >
                    <span>{{ props.row.document }}</span>
                  </b-table-column>
                  <b-table-column
                    field="consent"
                    label="Tipo de Consentimiento"
                    centered
                    v-slot="props"
                  >
                    <span>{{ props.row.consent_template }}</span>
                  </b-table-column>
                  <b-table-column
                    field="estado"
                    label="Estado"
                    centered
                    v-slot="props"
                  >
                    <span class="tag" :class="getTag(props.row.status_id)">
                      {{ props.row.status_id }}
                    </span>
                  </b-table-column>
                </b-table>
              </div>
              <div v-else>
                <b-message type="is-info"> Sin datos para procesar. </b-message>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="columns is-mobile is-multiline is-centered">
        <div class="column is-3">
          <b-field grouped>
            <b-tag type="is-primary" class="control" size="is-medium"
              >Transacciones Exitosas: {{ total_exitosas }}</b-tag
            >
          </b-field>
        </div>
        <div class="column is-3">
          <b-field grouped>
            <b-tag type="is-warning" class="control" size="is-medium"
              >Transacciones Fallidas: {{ total_fallidas }}</b-tag
            >
          </b-field>
        </div>
      </div>
    </div>
    <b-loading
      :is-full-page="true"
      v-model="isLoading"
      :can-cancel="true"
    ></b-loading>
    <b-modal v-model="isCardModalActive" :width="640" scroll="keep">
      <div class="card">
        <div class="card-image">
          <figure class="image is-4by3">
            <img src="../../assets/en_desarrollo.png" alt="Image" />
          </figure>
        </div>
        <div class="card-content">
          <div class="media">
            <div class="media-left">
              <figure class="image is-48x48">
                <img src="../../assets/icono.png" alt="Image" />
              </figure>
            </div>
            <div class="media-content">
              <p class="title is-4">Función en construcción</p>
              <p class="subtitle is-6">@Infomedia</p>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </main-layout>
</template>

<script>
import moment from "moment";
import MainLayout from "@/components/Layout/MainLayout";
import { mapMutations, mapActions, mapState } from "vuex";

export default {
  name: "CConsent",
  components: { MainLayout },
  data() {
    return {
      total_exitosas: 0,
      total_fallidas: 0,
      isPaginated: true,
      isPaginationRounded: true,
      perPage: 10,
      isLoading: false,
      dataTable: [],
      counter: 0,
      fecha: [],
      fechaInicial: "",
      fechaFinal: "",
      locale: "en-CA",
      maxDate: new Date(),
      isCardModalActive: false,
    };
  },
  computed: {
    ...mapState(["_HOST_SERVE", "_PERFIL_MODULO_"]),
  },
  mounted() {
    this.obtenerRangoFecha();
    this.$emit("tituloTransaccion", "Transacciones de Firmado");
    this.nombreTituloMenu("Transacciones de Firmado");
  },
  methods: {
    obtenerRangoFecha() {
      const options = {
        method: "get",
        uri: "boards/getRangeDate",
      };
      this._axios(options)
        .then((response) => {
          this.maxDate = new Date(response.data[1]);
        })
        .catch((error) => {
          this.isLoadingTable = false;
          console.log(error);
        })
        .finally(() => this.cancelarLoading());
      this.handleSubmit();
    },
    handleSubmit() {
      if (this._PERFIL_MODULO_.transacciones_consent) {
        this.isLoading = true;
        if (this.fecha == null) {
          this.alertCustomError("Debe seleccionar un mes");
          return;
        }
        if (this.fecha.length === 0) {
          this.fechaInicial = moment()
            .startOf("month")
            .format("YYYY-MM-DD hh:mm");
          this.fechaFinal = moment().endOf("month").format("YYYY-MM-DD hh:mm");
        } else {
          this.fechaInicial = this.fecha[0];
          this.fechaFinal = this.fecha[1];
        }

        this.dataTable = [];
        this.counter = 0;
        const options = {
          method: "post",
          uri: "consent/GetAll",
          data: {
            Fecha: new Date(),
            FechaInicial:
              this.fecha.length === 0 ? "0001-01-01" : this.fecha[0],
            FechaFinal: this.fecha.length === 0 ? "0001-01-01" : this.fecha[1],
          },
        };
        this._axios(options)
          .then((response) => {
            response.data.datos.forEach((item) => {
              this.counter++;
              item.counter = this.counter;
              this.dataTable.push(item);
            });
            this.total_exitosas = response.data.cant_exitosas;
            this.total_fallidas = response.data.cant_fallidas;
          })
          .catch((error) => {
            this.isLoadingTable = false;
            console.log(error);
          })
          .finally(() => this.cancelarLoading());
      } else {
        this.$router.push("login");
      }
    },
    exportCSV: function () {
      if (this.fechaInicial != "") {
        let fechaInicial = this.convertFecha(this.fechaInicial);
        let fechaFinal = this.convertFecha(this.fechaFinal);
        let routeData = `${this._HOST_SERVE}/export/exportCSV/${fechaInicial}/${fechaFinal}`;
        window.open(routeData, "_blank");
      }
    },
    exportExcel: function () {
      alert("En desarrollo");
    },
    exportPDF: function () {
      alert("En desarrollo");
    },
    convertFecha: function (fecha) {
      let date = new Date(fecha).toISOString().slice(0, 10);
      return date;
    },
    cancelarLoading() {
      setTimeout(() => {
        this.isLoading = false;
      }, 1500);
    },
    dateFormating(date) {
      const option = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      let dateFormated = new Date(date).toLocaleString("es-CO", option);
      return dateFormated[0].toUpperCase() + dateFormated.slice(1);
    },
    getTag(status) {
      if (status === "No Aceptado") return "is-danger";
      else if (status === "Aceptado") return "is-success";
      else return "is-warning";
    },
    statusFormating(status) {
      if (status == "3") return "Revocado";
      else if (status == "1") return "Aceptado";
      else return "No Aceptado";
    },
    ...mapMutations(["nombreTituloMenu"]),
    ...mapActions(["_axios"]),
  },
};
</script>

<style>
:root {
  --primary: #00522a;
}

.datepicker .datepicker-table .datepicker-body .datepicker-cell.is-selected {
  background-color: #00522a;
  color: #fff;
}

.pagination-link.is-current {
  background-color: #00522a;
  border-color: #00522a;
  color: #fff;
}

.tag:not(body).is-primary {
  background-color: #00522a;
  color: #fff;
}
.has-text-primary {
  color: #00522a !important;
}
.styled-select select {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  background: #00522a;
}
.select select:focus,
.taginput .taginput-container.is-focusable:focus,
.textarea:focus,
.input:focus,
.select select.is-focused,
.taginput .is-focused.taginput-container.is-focusable,
.is-focused.textarea,
.is-focused.input,
.select select:active,
.taginput .taginput-container.is-focusable:active,
.textarea:active,
.input:active,
.select select.is-active,
.taginput .is-active.taginput-container.is-focusable,
.is-active.textarea,
.is-active.input {
  border-color: #00522a;
}
.datepicker .dropdown .input[readonly]:focus,
.datepicker .dropdown .input[readonly].is-focused,
.datepicker .dropdown .input[readonly]:active,
.datepicker .dropdown .input[readonly].is-active,
.datepicker .dropdown-trigger .input[readonly]:focus,
.datepicker .dropdown-trigger .input[readonly].is-focused,
.datepicker .dropdown-trigger .input[readonly]:active,
.datepicker .dropdown-trigger .input[readonly].is-active {
  box-shadow: none;
}

.pagination-previous:focus,
.pagination-next:focus,
.pagination-link:focus {
  border-color: #00522a;
}

.datepicker .datepicker-table .datepicker-body .datepicker-cell.is-today {
  border: solid 1px rgba(0, 82, 42, 1);
}
</style>